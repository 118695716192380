import React from "react";

export default ({cta_1_text, cta_2_text, cta_3_text}) => (
  <section id="benefits" className="text-center font-weight-bold my-5">
    <div className="container py-3">
      <div className="row justify-content-center">
        <div className="col-4 col-md-4 mb-3 px-1 px-md-3">
          <div className="m-0">
            <div className="fa-stack mt-1">
              
            </div>
            <div className="ml-1">{cta_1_text}</div>
          </div>
        </div>

        <div className="col-4 col-md-4 mb-3 px-1 px-md-3">
          <div className="m-0">
            <div className="fa-stack mt-1">
              
            </div>
            <div className="ml-1">{cta_2_text}</div>
          </div>
        </div>

        <div className="col-4 col-md-4 mb-3 px-1 px-md-3">
          <div className="m-0">
            <div className="fa-stack mt-1">
              
            </div>
            <div className="ml-1">{cta_3_text}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
