import React from 'react'
import { Link } from 'gatsby'

export default ({ cards, title }) => (
  <section id="cards" className="mt-4 pb-3">
    <h2 className="mt-md-5 mb-4">{title}</h2>
    <div className="row display-flex">
      {cards.map((card, index) => (
        <div className="col-md-6 mb-3" key={`card_${index}`}>
          <div className="card h-100 shadow mb-3" data-aos="fade-left">
            <div className="card-body">
              <h3 className="h4 mt-3">{card.title}</h3>
              <p>{card.intro}</p>
              <Link className="mt-3 stretched-link" to={card.cta_link}>
                {card.cta_text}
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  </section>
)
