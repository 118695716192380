import React from "react";
import Image from "../hacks/image";
import { Link } from "gatsby";
import showdown from 'showdown'
const converter = new showdown.Converter()

export default ({image, title, text, cta_text, cta_link, image_leftright}) => (
<section>
    <div className="container ">
        <div className="row align-items-center mb-5 ">
            <div className={`mb-4 col-8 offset-2 offset-md-0 col-md-5 align-self-center ${image_leftright==='RIGHT' ? 'order-1 offset-md-1' : 'order-0'}`}>
                {!!image && <Image src={image} alt={title} className="img-fluid" />}
            </div>
            <div className="text-center text-md-left col-md-5 offset-md-1 align-middle">
                <div>
                    {title && <h2 className="h3 mb-3">{title}</h2>}
                    {text && <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(text) }}/>}
                    {cta_link && <Link to={cta_link} target="_blank" className="mt-4 btn shadow btn-primary mb-4">{cta_text}</Link>}
                </div>
            </div>
        </div>
    </div>
</section>
)