import React from 'react'
import Image from '../hacks/image'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import Typical from 'react-typical'

export default ({ bg, title }) => (
  <ParallaxProvider>
    <header className="extra-pad hero-home">
      <h1>James Dodd</h1>

      <h2 className="display-1 mb-0">
        <Typical
          steps={[title, 3000]}
          loop={false}
          wrapper="span"
        />
      </h2>
      
      <div className="hero-image_cover ">
        <Parallax y={[-20, 0]}>
          {!!bg && <Image src={bg} alt="" />}
        </Parallax>
      </div>
    </header>
    <Parallax y={[40, -100]}>
      <div id="grad"></div>
    </Parallax>
  </ParallaxProvider>
)
