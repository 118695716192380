import React from 'react'
import HeroBlock from './Blocks/HeroBlock'
import TitleBlock from './Blocks/TitleBlock'
import GeneralContentBlock from './Blocks/GeneralContentBlock'
import BenefitListBlock from './Blocks/BenefitListBlock'
import ImageTextBlock from './Blocks/ImageTextBlock'
import CardsBlock from './Blocks/CardsBlock'
import WorkBlock from './Blocks/workBlock'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'

const PageBuilder = ({ blocks }) => {
  return (
    <ParallaxProvider>
      {blocks &&
        blocks.map((block, index) => (
          <React.Fragment key={`block_${index}`}>
            {renderBlock(block)}
          </React.Fragment>
        ))}
    </ParallaxProvider>
  )
}
export default PageBuilder

const renderBlock = (block) => {
  // just standard if statement
  if (block.type === 'home_hero') {
    return (
      <HeroBlock
        bg={block.image}
        title={block.header}
        text={block.intro}
        cta_1_text={block.cta_1_text}
        cta_1_link={block.cta_1_link}
      />
    )
  } else if (block.type === 'image_text') {
    return (
      <ImageTextBlock
        image={block.image}
        title={block.title}
        text={block.text}
        cta_text={block.cta_text}
        cta_link={block.cta_link}
        image_leftright={block.image_leftright}
      />
    )
  } else if (block.type === 'work_block') {
    return (
      <React.Fragment>
        <Parallax x={[30, 1]}>
          <h2 className="massive text-uppercase">{block.title}</h2>
        </Parallax>
        <div className="row justify-content-center">
          {block.work.map((work, index) => (
            <WorkBlock
              work={work}
              image_leftright={index % 2 ? 'LEFT' : 'RIGHT'}
              key={`work_${index}`}
            />
          ))}
        </div>
      </React.Fragment>
    )
  } else if (block.type === 'benefits') {
    return (
      <BenefitListBlock
        cta_1_text={block.cta_1_text}
        cta_2_text={block.cta_2_text}
        cta_3_text={block.cta_3_text}
      />
    )
  } else if (block.type === 'cards') {
    return <CardsBlock cards={block.cards} title={block.title} />
  } else if (block.type === 'general_content') {
    return (
      <GeneralContentBlock
        html={block.body}
        include_form={block.include_form}
        full_width={block.full_width}
      />
    )
  } else if (block.type === 'title_block') {
    return <TitleBlock title={block.title} />
  }
}
